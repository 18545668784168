<template>
  <div class="card card--table">
    <div
      v-b-toggle="`collapseRelatedInfo`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">View related jobs</a> <span class="badge bg-light-success">{{ data.length }}</span>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseRelatedInfo`"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <div
              v-for="d in data"
              :key="d.id"
              class="form-check-success form-switch mb-2"
            >
              <input
                :id="`relatedJob${d.id}`"
                v-model="related"
                :value="d.id"
                type="checkbox"
                class="form-check-input"
                @click="saveNewRelated"
              >
              <label
                class="form-check-label ms-1"
                :for="`relatedJob${d.id}`"
              > {{ d.name }}</label>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    data: { type: Array, required: false, default: () => [] },
    selectedData: { type: Array, required: false, default: () => [] },
  },
  data() {
    return {
      related: [],
    }
  },
  async mounted() {
    this.related = this.selectedData.map(x => x.id)
    setTimeout(() => {
      this.$emit('saveRelatedInfo', this.related)
    }, 100)

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveNewRelated() {
      setTimeout(() => {
        this.$emit('saveRelatedInfo', this.related)
      }, 100)
    },
  },
}
</script>
